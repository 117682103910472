import React from "react";

const skillsContent = [
  { skillClass: "p90", skillPercent: "90", skillName: "HTML" },
  { skillClass: "p89", skillPercent: "89", skillName: "JAVASCRIPT" },
  { skillClass: "p90", skillPercent: "90", skillName: "CSS-SCSS" },
  { skillClass: "p88", skillPercent: "88", skillName: "REACT - REACT NATIVE" },
  { skillClass: "p89", skillPercent: "89", skillName: "NEXT" },
  { skillClass: "p95", skillPercent: "95", skillName: "SHOPIFY" },
  { skillClass: "p84", skillPercent: "84", skillName: "NODE" },
  { skillClass: "p70", skillPercent: "70", skillName: "PHP" },
  { skillClass: "p80", skillPercent: "80", skillName: "WORDPRESS" },
  { skillClass: "p78", skillPercent: "78", skillName: "JQUERY" },
  { skillClass: "p70", skillPercent: "70", skillName: "ANGULAR" },
  { skillClass: "p76", skillPercent: "76", skillName: "AWS - NGINX - APACHE" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
