import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Hoan" },
  { meta: "last name", metaInfo: "Nguyen" },
  { meta: "Age", metaInfo: "26 Years" },
  { meta: "Nationality", metaInfo: "VietNam" },
  { meta: "Freelance", metaInfo: "Available" },
  { meta: "Address", metaInfo: "Binh Thanh, Ho Chi Minh City" },
  { meta: "phone", metaInfo: "+84 377.601.559." },
  { meta: "Email", metaInfo: "hoandev98@gmail.com" },
  { meta: "Skype", metaInfo: "dinhhoannguyen999" },
  { meta: "langages", metaInfo: "Vietnam, French, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
