import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import Blog from "../components/blog/Blog";

import ModalOneBlogContent from "../components/blog/modal/ModalOneBlogContent";
const menuItem = [
  { icon: "fa-home", menuName: "Detail", link: "/" },
  { icon: "fa-comments", menuName: "Blog" },
];

const Detail = () => {
  return (
    <div className="yellow">
      <Tabs>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Blog Content Starts */}
          <TabPanel className="home">
            <ModalOneBlogContent />
          </TabPanel>
          {/* Blog Content Ends */}
          {/* Blog Content Starts */}
          <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>blog</span>
              </h1>
              <span className="title-bg">posts</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
                <Blog />
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Blog Content Ends */}
        </div>
      </Tabs>
    </div>
  );
};

export default Detail;
