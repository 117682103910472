import React from "react";
import Modal from "react-modal";
// import { Link } from "react-router-dom";
import data from "../../data";
import parse from "html-react-parser";

Modal.setAppElement("#root");

const Blog = () => {
  return (
    <>
      {data.map((item, i) => {
        return (
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30" key={i}>
            <a href={`/blog/${item.slug}`} className="text-decoration-none">
              <article className="post-container">
                <div className="post-thumb">
                  <div className="d-block position-relative overflow-hidden">
                    <img
                      src={item.thumbnailUrl}
                      className="img-fluid"
                      alt="Blog Post"
                    />
                  </div>
                </div>
                {/* End .thumb */}
                <div className="post-content">
                  <div className="entry-header">
                    <h3>{item.title}</h3>
                  </div>
                  <div className="entry-content open-sans-font">
                    {parse(item.shortDes)}
                  </div>
                  <div className="entry-content fs-12 text-info open-sans-font">
                    {item.created}
                  </div>
                </div>
                {/* End .post-content */}
              </article>
            </a>
          </div>
        );
      })}
    </>
  );
};

export default Blog;
