import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import data from "../../../data";
import NotFound from "../../../views/NotFound";
import parse from "html-react-parser";

const ModalOneBlogContent = () => {
  const [item, setItem] = useState();
  const { slug } = useParams();

  useEffect(() => {
    if (data.length !== 0) {
      setItem(data.filter((item) => item.slug === slug));
    }
  }, [slug]);
  // console.log(item);
  if (!item) {
    return <div>Loading...</div>;
  }
  if (item && item.length === 0) {
    return <NotFound />;
  }

  return (
    //  Article Starts
    <article className="blog-detail container">
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}
      <div className="backtohome">
        <Link to="/" className="text-danger">
          <span>
            <i className="fa fa-arrow-left"></i> Go to home
          </span>
        </Link>
      </div>
      <div className="meta open-sans-font">
        <span className="date">
          <i className="fa fa-calendar"></i> {item[0].created}
        </span>
        <span className="ms-3">
          <i className="fa fa-tags"></i> {item[0].tags}
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>{item[0].title}</h1>
      <img src={item[0].thumbnailUrl} className="img-fluid banner" alt="Blog" />
      <div className="blog-excerpt open-sans-font pb-5">
        {parse(item[0].content)}

        {/* <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
            Most photographers find it hard to see interesting pictures in
            places in which they are most familiar. A trip somewhere new seems
            always exactly what our photography needed, as shooting away from
            home consistently inspires us to new artistic heights.
          </p>
        </div> */}
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalOneBlogContent;
