import React from "react";

const experienceContent = [
  {
    year: "2020 - Present",
    position: " Fullstask Developer",
    compnayName: "Itsforce.com",
    details: `  Build system store ecommerce ( Shopify, ReactJs, NextJs )`,
  },
  // {
  //   year: "2021 - 2023",
  //   position: " Frontend Developer",
  //   compnayName: "boostcommerce.net",
  //   details: `Build App and manage company website`,
  // },
  {
    year: "2018 - 2020",
    position: "Developer of SMB team",
    compnayName: "NETPOWER AS",
    details: `Implement system store ecommerce ( magento 2, wordpress ), add features and maintain on Laravel, Moodle, maintenance, upgrade, optimize Website, full-stack on React Native, ReactJs, NodeJs`,
  },
  {
    year: "2017 - 2018",
    position: "Front-end developer",
    compnayName: "STHRIVE",
    details: `Convert designs to pure html/css/js/elm which is the cleanest and most optimal`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
